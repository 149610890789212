import { CloudImage, Heading, Text } from "@fluidattacks/design";
import dayjs from "dayjs";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "styled-components";

import { LoginProviders } from "./login-providers";
import {
  FormContainer,
  FormWrapper,
  HeaderContainer,
  LoginForm,
} from "./styles";

import { StyledLink } from "../styles";

const ProviderForm: React.FC = (): JSX.Element => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <FormWrapper>
      <FormContainer>
        <LoginForm>
          <HeaderContainer>
            <CloudImage
              alt={"Fluid Attacks Logo"}
              publicId={"integrates/login/loginLogoExtended"}
              width={"220px"}
            />
            <HeaderContainer gap={0.5}>
              <Heading
                fontWeight={"bold"}
                lineSpacing={2.5}
                size={"lg"}
                textAlign={"center"}
              >
                {t("login.header.title1")}
                <br />
                {t("login.header.title2")}
              </Heading>
              <Text
                color={theme.palette.gray[500]}
                lineSpacing={1.75}
                size={"xl"}
                textAlign={"center"}
              >
                {t("login.header.text")}
                <StyledLink color={theme.palette.primary[500]} to={"/SignUp"}>
                  {t("login.header.link")}
                </StyledLink>
              </Text>
            </HeaderContainer>
          </HeaderContainer>
          <LoginProviders section={"Login"} />
          <Text
            color={theme.palette.gray[400]}
            size={"md"}
            textAlign={"center"}
          >
            <StyledLink to={"https://fluidattacks.com/terms-use/"}>
              {t("login.footer.term1")}
            </StyledLink>
            {t("login.footer.term2")}
            <StyledLink to={"https://fluidattacks.com/privacy/"}>
              {t("login.footer.term3")}
            </StyledLink>
            <br />
            {t("login.footer.copyright", { year: dayjs().year() })}
          </Text>
        </LoginForm>
      </FormContainer>
    </FormWrapper>
  );
};

export { ProviderForm };
