import type { CloudinaryImage } from "@cloudinary/url-gen";
import { Cloudinary } from "@cloudinary/url-gen";
import { useMemo } from "react";

interface IUseCloudinaryImageProps {
  publicId: string;
  format?: string;
}

export const useCloudinaryImage = ({
  publicId,
  format = "webp",
}: IUseCloudinaryImageProps): CloudinaryImage => {
  const cloudinaryInstance = useMemo((): Cloudinary => {
    return new Cloudinary({
      cloud: {
        cloudName: "fluid-attacks",
      },
    });
  }, []);

  const image = useMemo((): CloudinaryImage => {
    return cloudinaryInstance.image(publicId).format(format);
  }, [cloudinaryInstance, publicId, format]);

  return image;
};
