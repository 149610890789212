import { useEffect } from "react";
import * as React from "react";
import { Helmet } from "react-helmet";

import { BigScreenSignup } from "./big-screen";

import { setReferrerURL } from "utils/page-sense-events";

const SignUp: React.FC = (): JSX.Element => {
  const referrerURL = document.referrer;

  setReferrerURL(referrerURL);

  useEffect((): VoidFunction => {
    sessionStorage.setItem("trial", "true");

    return (): void => {
      sessionStorage.removeItem("trial");
    };
  }, []);

  return (
    <React.Fragment>
      <Helmet>
        <title>{"Sign up | Fluid Attacks"}</title>
      </Helmet>
      <BigScreenSignup />
    </React.Fragment>
  );
};

export { SignUp };
