const CI_COMMIT_REF_NAME = "trunk";
const CI_COMMIT_SHA = "e9e99e247fe19e21aba7f2cbbba29a0d991e8e6e";
const CI_COMMIT_SHORT_SHA = "e9e99e24";
const INTEGRATES_BUCKET_NAME = "integrates.front.fluidattacks.com";

export {
  CI_COMMIT_REF_NAME,
  CI_COMMIT_SHA,
  CI_COMMIT_SHORT_SHA,
  INTEGRATES_BUCKET_NAME,
};
