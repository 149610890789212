import { create } from "zustand";

type TVulnAction =
  | "close"
  | "delete"
  | "none"
  | "reattack"
  | "resubmit"
  | "severity"
  | "verify";
interface IVulnsState {
  isAcceptanceModalOpen: boolean;
  isApprovingSeverityUpdate: boolean;
  isClosing: boolean;
  isDeleteModalOpen: boolean;
  isDeleting: boolean;
  isEditing: boolean;
  isNotify: boolean;
  isReattacking: boolean;
  isResubmitting: boolean;
  isUpdatingSeverity: boolean;
  isVerifyModalOpen: boolean;
  isVerifying: boolean;
  vulnAction: TVulnAction;
}
interface IVulnsStore extends IVulnsState {
  resetVulnsStore: () => void;
  setIsApprovingSeverityUpdate: (value: boolean) => void;
  setIsAcceptanceModalOpen: (value: boolean) => void;
  setIsClosing: (value: boolean) => void;
  setIsDeleteModalOpen: (value: boolean) => void;
  setIsDeleting: (value: boolean) => void;
  setIsEditing: (value: boolean) => void;
  setIsNotify: (value: boolean) => void;
  setIsReattacking: (value: boolean) => void;
  setIsResubmitting: (value: boolean) => void;
  setIsUpdatingSeverity: (value: boolean) => void;
  setIsVerifying: (value: boolean) => void;
  setIsVerifyModalOpen: (value: boolean) => void;
  setVulnAction: (value: TVulnAction) => void;
}

const initialVulnsState: IVulnsState = {
  isAcceptanceModalOpen: false,
  isApprovingSeverityUpdate: false,
  isClosing: false,
  isDeleteModalOpen: false,
  isDeleting: false,
  isEditing: false,
  isNotify: false,
  isReattacking: false,
  isResubmitting: false,
  isUpdatingSeverity: false,
  isVerifyModalOpen: false,
  isVerifying: false,
  vulnAction: "none",
};

const useVulnerabilityStore = create<IVulnsStore>()(
  (set): IVulnsStore => ({
    ...initialVulnsState,
    resetVulnsStore: (): void => {
      set(initialVulnsState);
    },

    setIsAcceptanceModalOpen: (value): void => {
      set(
        (): Partial<IVulnsStore> => ({
          isAcceptanceModalOpen: value,
        }),
      );
    },
    setIsApprovingSeverityUpdate: (value): void => {
      set(
        (): Partial<IVulnsStore> => ({
          isApprovingSeverityUpdate: value,
        }),
      );
    },
    setIsClosing: (value): void => {
      set(
        (): Partial<IVulnsStore> => ({
          isClosing: value,
        }),
      );
    },
    setIsDeleteModalOpen: (value): void => {
      set(
        (): Partial<IVulnsStore> => ({
          isDeleteModalOpen: value,
        }),
      );
    },
    setIsDeleting: (value): void => {
      set(
        (): Partial<IVulnsStore> => ({
          isDeleting: value,
        }),
      );
    },
    setIsEditing: (value): void => {
      set(
        (): Partial<IVulnsStore> => ({
          isEditing: value,
        }),
      );
    },
    setIsNotify: (value): void => {
      set(
        (): Partial<IVulnsStore> => ({
          isNotify: value,
        }),
      );
    },
    setIsReattacking: (value): void => {
      set(
        (): Partial<IVulnsStore> => ({
          isReattacking: value,
        }),
      );
    },
    setIsResubmitting: (value): void => {
      set(
        (): Partial<IVulnsStore> => ({
          isResubmitting: value,
        }),
      );
    },
    setIsUpdatingSeverity: (value): void => {
      set(
        (): Partial<IVulnsStore> => ({
          isUpdatingSeverity: value,
        }),
      );
    },
    setIsVerifyModalOpen: (value): void => {
      set(
        (): Partial<IVulnsStore> => ({
          isVerifyModalOpen: value,
        }),
      );
    },
    setIsVerifying: (value): void => {
      set(
        (): Partial<IVulnsStore> => ({
          isVerifying: value,
        }),
      );
    },
    setVulnAction: (value): void => {
      set(
        (): Partial<IVulnsStore> => ({
          vulnAction: value,
        }),
      );
    },
  }),
);

export type { TVulnAction };
export { useVulnerabilityStore };
