import type { ApolloQueryResult } from "@apollo/client";
import dayjs, { extend } from "dayjs";
import utc from "dayjs/plugin/utc";
import type React from "react";
import { createContext } from "react";

import type { Exact, GetCurrentUserAtHomeQuery } from "gql/graphql";
import { translate } from "utils/translations/translate";

interface IUser {
  awsSubscription: {
    contractedGroups: number | null;
    status: string;
    usedGroups: number;
  } | null;
  refetch?: (
    variables?: Partial<Exact<Record<string, never>>>,
  ) => Promise<ApolloQueryResult<GetCurrentUserAtHomeQuery>>;
  tours: {
    newGroup: boolean;
    newRoot: boolean;
    welcome: boolean;
  };
  trialAdmin?: boolean;
  userEmail: string;
  userInTrial?: boolean;
  userIntPhone?: string;
  userName: string;
}

interface IAuthContext extends IUser {
  setUser?: React.Dispatch<React.SetStateAction<IUser>>;
}

const authContext = createContext<IAuthContext>({
  awsSubscription: null,
  tours: {
    newGroup: false,
    newRoot: false,
    welcome: false,
  },
  userEmail: "",
  userName: "",
});

extend(utc);

const setupSessionCheck = (expDate: string): void => {
  setTimeout((): void => {
    location.replace("/logout");
    // eslint-disable-next-line no-alert -- Deliberate usage
    alert(translate.t("validations.validSessionDate"));
  }, dayjs.utc(expDate).diff(dayjs.utc()));
};

export type { IAuthContext };
export { authContext, setupSessionCheck };
