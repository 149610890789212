import { graphql } from "gql";

const GET_GROUP_SERVICES = graphql(`
  query GetGroupServices($groupName: String!) {
    group(groupName: $groupName) {
      name
      serviceAttributes
    }
  }
`);

const GET_STAKEHOLDER_TRIAL = graphql(`
  query GetStakeholderTrial {
    me {
      userEmail
      trial {
        completed
        extensionDate
        extensionDays
        startDate
        state
      }
    }
  }
`);

const UPDATE_TOURS = graphql(`
  mutation updateTours(
    $newGroup: Boolean!
    $newRoot: Boolean!
    $welcome: Boolean!
  ) {
    updateTours(
      tours: { newGroup: $newGroup, newRoot: $newRoot, welcome: $welcome }
    ) {
      success
    }
  }
`);

const GET_USER_ORGANIZATIONS = graphql(`
  query GetUserOrganizations {
    me {
      __typename
      userEmail
      organizations {
        name
      }
    }
  }
`);

const ADD_AUDIT_EVENT = graphql(`
  mutation AddAuditEvent($object: String!, $objectId: ID!) {
    addAuditEvent(object: $object, objectId: $objectId) {
      success
    }
  }
`);

export {
  ADD_AUDIT_EVENT,
  GET_GROUP_SERVICES,
  GET_STAKEHOLDER_TRIAL,
  GET_USER_ORGANIZATIONS,
  UPDATE_TOURS,
};
