import { graphql } from "gql";

const GET_ORGANIZATION_POLICIES = graphql(`
  query GetOrganizationPoliciesAtOrganization($organizationId: String!) {
    organization(organizationId: $organizationId) {
      daysUntilItBreaks
      inactivityPeriod
      maxAcceptanceDays
      maxAcceptanceSeverity
      maxNumberAcceptances
      minAcceptanceSeverity
      minBreakingSeverity
      name
      userRole
      vulnerabilityGracePeriod
    }
  }
`);

const GET_FINDINGS_POLICIES = graphql(`
  query GetFindingsPolicies($organizationId: String!) {
    organization(organizationId: $organizationId) {
      __typename
      name
      userRole
      findingPolicies {
        id
        __typename
        lastStatusUpdate
        name
        status
        tags
        treatmentAcceptance
      }
    }
  }
`);

const GET_POLICIES = graphql(`
  query GetPoliciesAtOrganization($organizationId: String!) {
    organization(organizationId: $organizationId) {
      __typename
      daysUntilItBreaks
      inactivityPeriod
      maxAcceptanceDays
      maxAcceptanceSeverity
      maxNumberAcceptances
      minAcceptanceSeverity
      minBreakingSeverity
      name
      vulnerabilityGracePeriod
      groups {
        __typename
        daysUntilItBreaks
        maxAcceptanceDays
        maxAcceptanceSeverity
        maxNumberAcceptances
        minAcceptanceSeverity
        minBreakingSeverity
        name
        vulnerabilityGracePeriod
      }
    }
  }
`);

const GET_ORGANIZATION_PRIORITY_POLICIES = graphql(`
  query GetOrganizationPriorityPolicies($organizationId: String!) {
    organization(organizationId: $organizationId) {
      __typename
      name
      priorityPolicies {
        __typename
        policy
        value
      }
    }
  }
`);

const UPDATE_ORGANIZATION_POLICIES = graphql(`
  mutation UpdateOrganizationPolicies(
    $daysUntilItBreaks: Int
    $inactivityPeriod: Int
    $maxAcceptanceDays: Int
    $maxAcceptanceSeverity: Float
    $maxNumberAcceptances: Int
    $minAcceptanceSeverity: Float
    $minBreakingSeverity: Float
    $organizationId: String!
    $organizationName: String!
    $vulnerabilityGracePeriod: Int
  ) {
    updateOrganizationPolicies(
      daysUntilItBreaks: $daysUntilItBreaks
      inactivityPeriod: $inactivityPeriod
      maxAcceptanceDays: $maxAcceptanceDays
      maxAcceptanceSeverity: $maxAcceptanceSeverity
      maxNumberAcceptances: $maxNumberAcceptances
      minAcceptanceSeverity: $minAcceptanceSeverity
      minBreakingSeverity: $minBreakingSeverity
      organizationId: $organizationId
      organizationName: $organizationName
      vulnerabilityGracePeriod: $vulnerabilityGracePeriod
    ) {
      success
    }
  }
`);

const UPDATE_GROUPS_POLICIES = graphql(`
  mutation updateGroupsPolicies(
    $daysUntilItBreaks: Int
    $groups: [String!]!
    $maxAcceptanceDays: Int
    $maxAcceptanceSeverity: Float
    $maxNumberAcceptances: Int
    $minAcceptanceSeverity: Float
    $minBreakingSeverity: Float
    $organizationId: String!
    $vulnerabilityGracePeriod: Int
  ) {
    updateGroupsPolicies(
      daysUntilItBreaks: $daysUntilItBreaks
      groups: $groups
      maxAcceptanceDays: $maxAcceptanceDays
      maxAcceptanceSeverity: $maxAcceptanceSeverity
      maxNumberAcceptances: $maxNumberAcceptances
      minAcceptanceSeverity: $minAcceptanceSeverity
      minBreakingSeverity: $minBreakingSeverity
      organizationId: $organizationId
      vulnerabilityGracePeriod: $vulnerabilityGracePeriod
    ) {
      success
    }
  }
`);

const REMOVE_ORGANIZATION_PRIORITY_POLICY = graphql(`
  mutation RemoveOrganizationPriorityPolicy(
    $organizationId: String!
    $priorityPolicy: String!
  ) {
    removeOrganizationPriorityPolicy(
      organizationId: $organizationId
      priorityPolicy: $priorityPolicy
    ) {
      success
    }
  }
`);

const UPDATE_ORGANIZATION_PRIORITY_POLICIES = graphql(`
  mutation UpdateOrganizationPriorityPolicies(
    $organizationId: String!
    $priorityPolicies: [PriorityPolicyInput!]
  ) {
    updateOrganizationPriorityPolicies(
      organizationId: $organizationId
      priorityPolicies: $priorityPolicies
    ) {
      success
    }
  }
`);

export {
  GET_ORGANIZATION_POLICIES,
  GET_ORGANIZATION_PRIORITY_POLICIES,
  REMOVE_ORGANIZATION_PRIORITY_POLICY,
  UPDATE_ORGANIZATION_POLICIES,
  UPDATE_ORGANIZATION_PRIORITY_POLICIES,
  GET_POLICIES,
  GET_FINDINGS_POLICIES,
  UPDATE_GROUPS_POLICIES,
};
