import * as React from "react";
import { useTranslation } from "react-i18next";

import type { ILaptopModalFpProps } from "./types";

import { PopUpMobile } from "components/pop-up-mobile";
import { openUrl } from "utils/resource-helpers";

const LaptopModal: React.FC<ILaptopModalFpProps> = ({
  isOpen,
}): JSX.Element | null => {
  const { t } = useTranslation();

  if (isOpen) {
    return (
      <PopUpMobile
        confirmButton={{
          onClick: (): void => {
            openUrl("https://fluidattacks.com/");
          },
          text: t("dashboard.minimumWidth.button"),
        }}
        darkBackground={true}
        description={t("dashboard.minimumWidth.description2")}
        image={{
          imageAlt: "viewMobileError",
          imageHeight: "175px",
          imageSrc: "integrates/resources/mobile",
          imageWidth: "100%",
        }}
        title={t("dashboard.minimumWidth.title")}
        width={"335px"}
      />
    );
  }

  return null;
};

export { LaptopModal };
