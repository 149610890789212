import { placeholder } from "@cloudinary/react";
import { CloudImage, Container } from "@fluidattacks/design";
import * as React from "react";

import { ClientsContainer, SlideShow } from "./styles";

const Slider: React.FC = (): JSX.Element => {
  const clients = [
    "bancolombia",
    "itau",
    "avianca",
    "oxxo",
    "copa-airlines-updated",
    "consubanco",
    "banco-inter-chile",
    "interbank",
    "banbif",
    "banistmo",
    "gesa-updated",
    "bantrab",
    "lulo-bank",
    "global-bank-updated",
    "banco-general",
  ];

  const slider = [0, 1].map((re): JSX.Element[] =>
    clients.map((client): JSX.Element => {
      const key = `${client}-${re}`;

      return (
        <Container
          display={"flex"}
          justify={"center"}
          key={key}
          width={"140px"}
          wrap={"wrap"}
        >
          <CloudImage
            alt={key}
            height={"80px"}
            key={key}
            plugins={[placeholder()]}
            publicId={`airs/home/ClientSection/updateLogos/${client}-logo`}
            width={"140px"}
          />
        </Container>
      );
    }),
  );

  return (
    <ClientsContainer>
      <SlideShow>{slider}</SlideShow>
    </ClientsContainer>
  );
};

export { Slider };
